<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="170px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="姓名" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="tel">
            <el-input v-model="form.tel" placeholder="请输入联系方式" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证号" prop="idNum">
            <el-input v-model="form.idNum" placeholder="请输入身份证号" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="紧急联系人姓名" prop="mgcContactName">
            <el-input v-model="form.mgcContactName" placeholder="请输入紧急联系人姓名" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="紧急联系人于本人关系" prop="mgcContactShip">
            <el-input v-model="form.mgcContactShip" placeholder="请输入紧急联系人于本人关系" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="紧急联系人方式" prop="mgcContactTel">
            <el-input v-model="form.mgcContactTel" placeholder="请输入紧急联系人方式" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="身份特征" prop="identityCharacteristics">
            <el-input v-model="form.identityCharacteristics" placeholder="请输入身份特征" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="施工单位" prop="bedTransCompId">
            <el-input v-model="form.bedTransCompId" placeholder="请选择施工单位" auto-complete="off" :disabled="true">
              <el-button slot="append" type="primary" icon="el-icon-search" @click="selectBedCompany()" />
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="市" prop="city">
            <el-input v-model="form.city" placeholder="请输入市" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="区" prop="area">
            <el-input v-model="form.area" placeholder="请输入区" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="街道" prop="street">
            <el-input v-model="form.street" placeholder="请输入街道" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="社区" prop="community">
            <el-input v-model="form.community" placeholder="请输入社区" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="form.address" type="textarea" :rows="3" placeholder="请输入详细地址" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="改造项目名称" prop="projectName">
            <el-input v-model="form.projectName" type="textarea" :rows="3" placeholder="请输入改造项目名称" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="改造图片" prop="picture">
            <upload-img :limit="6" :upload-lists="imgsList" @uploadChange="uploadSuccess" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择施工单位" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          机构名称：
          <el-input v-model="searchData.orgName" style="width:200px;margin-right:10px;" size="medium" placeholder="请输入服务站点名称" />
          <el-button size="medium" type="primary" @click="search">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table ref="singleTable" :data="tableData" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  v-model="radio"
                  :label="scope.$index"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                  @change.native="getCurrentRow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="orgName" label="机构名称" width="120" />
            <el-table-column prop="orgStaff" label="机构员工" width="120" />
            <el-table-column prop="principalPersonName" label="负责人" width="120" />
            <el-table-column prop="qualificationDesc" label="施工资质" width="116" />
            <el-table-column prop="address" label="办公地址" width="120" />
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              :current-page="tablePage.currentPage"
              :page-sizes="[5, 10, 15]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    return {
      photoUrl: '',
      imgsList: [],
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      visible: false,
      searchData: {
        orgName: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      },
      titleName: '',
      form: {},
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系方式格式' }
        ],
        idNum: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请检查身份证号格式' }
        ],
        mgcContactName: [{ required: true, message: '请输入紧急联系人姓名', trigger: 'blur' }],
        mgcContactShip: [{ required: true, message: '请输入紧急联系人于本人关系', trigger: 'blur' }],
        mgcContactTel: [
          { required: true, message: '请输入紧急联系人方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查紧急联系人方式格式' }
        ],
        identityCharacteristics: [{ required: true, message: '请输入身份特征', trigger: 'blur' }],
        projectName: [{ required: true, message: '请输入改造项目名称', trigger: 'blur' }],
        // picture: [{ required: true, message: '请输入改造图片', trigger: 'blur' }],
        bedTransCompId: [{ required: true, message: '请选择施工单位', trigger: 'blur' }],
        city: [{ required: true, message: '请输入市', trigger: 'blur' }],
        area: [{ required: true, message: '请输入区', trigger: 'blur' }],
        street: [{ required: true, message: '请输入街道', trigger: 'blur' }],
        community: [{ required: true, message: '请输入社区', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }]
      }
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['downloadFile', 'addOrSaveBedPerson', 'detailBedPerson', 'getBedCompanyList']),
    getCurrentRow(row) {
      this.form.bedTransCompId = row.id
    },
    search() {
      this.getBedComanyInfo()
    },
    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getBedComanyInfo()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getBedComanyInfo()
    },
    getBedComanyInfo() {
      const params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          orgName: this.searchData.orgName
        }
      }
      const server = 'getBedCompanyList'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    selectBedCompany() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
      this.getBedComanyInfo()
    },
    async getDetail() {
      if (this.routeType == 'add') {
        this.titleName = '添加人员信息'
      } else {
        this.titleName = '修改人员信息'
        this.itemData = JSON.parse(this.$route.query.item)
        // 这里调用请求编辑时的详情接口渲染form表单数据

        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'detailBedPerson'
        await this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              tel: data.tel,
              idNum: data.idNum,
              mgcContactName: data.mgcContactName,
              mgcContactShip: data.mgcContactShip,
              mgcContactTel: data.mgcContactTel,
              identityCharacteristics: data.identityCharacteristics,
              projectName: data.projectName,
              picture: data.picture,
              bedTransCompId: data.bedTransCompId,
              city: data.city,
              area: data.area,
              street: data.street,
              community: data.community,
              address: data.address
            }
          }
        })
        if (this.form.picture) {
          this.getFileDetail(this.form.picture)
        }
      }
    },
    getFileDetail(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.imgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess(data) {
      this.form.picture = data.join(',')
    },
    submitEvent(formName) {
      if (!this.form.picture) {
        this.$XModal.message({
          message: '请上传改造图片',
          status: 'warning'
        })
        return
      }
      if (!this.form.bedTransCompId) {
        this.$XModal.message({
          message: '请检查施工单位是否已选择',
          status: 'warning'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            projectId: 0,
            ...this.form
          }
          const server = 'addOrSaveBedPerson'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
